import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Alert,
  Loading,
  Container,
} from '../../components';
import { useNavigate } from "react-router-dom";
import faceApiService from '../../services/faceApiService';
import urlUtils from '../../utils/urlUtils';
import FaceDetectionModel from '../../models/FaceDetectionModel';
import jsonService from '../../services/jsonService';
import fileUtils from '../../utils/fileUtils';

import CameraPhoto from './CameraPhoto';
import EmbedInstructions from './EmbedInstructions';
import EndMessage from './EndMessage';
import PreForm from './PreForm';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const FaceCollectorFace = (props) => {

  const navigate = useNavigate();
  const params = urlUtils.getSearchParams();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [faceScanControl, setFaceScanControl] = React.useState({
    eventId: '',
    system: '',
    guestId: '',
    score: 95,
    openPreForm: false,
    openCamera: false,
  });

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if ((!store.getState().user.id || !store.getState().user.email) && params?.public !== 'true') {
        navigate('/Login', { replace: true });
      }

      await faceApiService.loadCaptureModels();

      await getTestImageAndTestFaceDetection();

      getPreForm();

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    async function getTestImageAndTestFaceDetection() {
      let _res = await fetch(require('../../assets/test_face.jpg'));
      let _blob = await _res.blob();
      let _file = new File([_blob], "test_face.jpg", { type: _blob.type });
      let _base64 = await fileUtils.convertFileToBase64(_file);
      await faceApiService.getFaceDetectionFromBase64ImageCapture(_base64);
    }

    function getPreForm() {
      try {
        let _faceScanControl = { ...faceScanControl };

        if (
          params?.public === 'true' && (
            !params?.eventId ||
            !params?.guestId ||
            !params?.system
          )) {
          navigate('/Login', { replace: true });
        }

        if (params?.eventId) {
          _faceScanControl.eventId = params.eventId;
        }
        if (params?.system) {
          _faceScanControl.system = params.system;
        }
        if (params?.guestId) {
          _faceScanControl.guestId = params.guestId;
        }
        if (params?.score) {
          _faceScanControl.score = parseInt(params.score);
        }

        if (!_faceScanControl.eventId || !_faceScanControl.system || !_faceScanControl.guestId) {
          _faceScanControl.openPreForm = true;
        } else {
          _faceScanControl.openCamera = true;
        }

        setFaceScanControl(_faceScanControl);
      } catch (error) {
        console.log('Error getPreForm', error);
        throw error;
      }
    }
  }, []);

  const tryOpenCamera = React.useCallback(async () => {
    try {
      let _errorMessage = validateForm();
      if (_errorMessage) {
        setAlertControl({
          open: true,
          title: '',
          message: _errorMessage,
          icon: 'danger'
        });
        return;
      }

      setFaceScanControl({
        ...faceScanControl,
        openPreForm: false,
        openCamera: true,
      });

    } catch (error) {
      console.log('Error tryOpenCamera', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    }

    function validateForm() {
      if (!faceScanControl.eventId) {
        return `${getDic("campo")} "ID ${getDic("evento")}" ${getDic("obrigatorio")}`;
      }
      if (!faceScanControl.system) {
        return `${getDic("campo")} "${getDic("sistema")}" ${getDic("obrigatorio")}`;
      }
      if (!faceScanControl.guestId) {
        return `${getDic("campo")} "ID ${getDic("participante")}" ${getDic("obrigatorio")}`;
      }
    }

  }, [faceScanControl]);

  const tryCaptureFace = React.useCallback(async (image = '') => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("salvando") });

      const _detections = await faceApiService.getFaceDetectionFromBase64ImageCapture(image);

      if (!_detections || _detections?.detection?.score < (faceScanControl.score / 100)) {
        setAlertControl({
          open: true,
          title: '',
          message: `${getDic("falha-detectar-rosto")}`,
          icon: 'danger'
        });
        return;
      }

      const _descriptions = [];
      _descriptions.push(_detections.descriptor);

      let _detection = await faceApiService.saveFaceDetection(FaceDetectionModel({
        eventId: faceScanControl.eventId,
        system: faceScanControl.system,
        guestId: faceScanControl.guestId,
        detection: jsonService.stringify(_descriptions),
      }));

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success'
      });

      setFaceScanControl({
        ...faceScanControl,
        openPreForm: false,
        openCamera: false,
      });

      window.top.postMessage(JSON.stringify({
        message: 'faceDetectionCollected',
        data: _detection
      }), '*');

    } catch (error) {
      console.log('Error tryCaptureFace', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  }, [faceScanControl]);

  return (
    <Container
      header={params?.public === 'true' ? null : `${getDic("capturar")} ${getDic("rosto")}`}
      padding={10}
      backButton={params?.public === 'true' ? false : true}
    >
      {params?.public !== 'true' && !faceScanControl.openCamera && (
        <EmbedInstructions
          dimensions={dimensions}
        />
      )}
      {faceScanControl.openPreForm && (
        <PreForm
          dimensions={dimensions}
          faceScanControl={faceScanControl}
          setFaceScanControl={setFaceScanControl}
          tryOpenCamera={tryOpenCamera}
        />
      )}
      {faceScanControl.openCamera && (
        <CameraPhoto
          params={params}
          dimensions={dimensions}
          tryCaptureFace={tryCaptureFace}
        />
      )}
      {!loadingControl.open && params?.public === 'true' && !faceScanControl.openPreForm && !faceScanControl.openCamera && (
        <EndMessage
          dimensions={dimensions}
        />
      )}

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default FaceCollectorFace;
