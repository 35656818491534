import React from 'react';
import {
  FormRow,
} from '../../components';
import Box from '@mui/material/Box';

import InteegraLogo from './InteegraLogo';

const AppVersionText = (props) => {
  const {
    dimensions,
  } = props;

  return (
    <Box style={{
      height: (dimensions.height / 2),
      backgroundColor: '#456091',
      borderRadius: '0px 0px 50px 50px',
      marginBottom: -(dimensions.height / 4)
    }}>
      <Box style={{ paddingTop: (dimensions.height / 10) }}>
        <InteegraLogo />
      </Box>
    </Box>
  )
};

export default AppVersionText;
