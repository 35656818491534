import React from 'react';

const ImageProp = ({
  file,
  url,
  title = '',
  width = null,
  padding = 0,
  paddingTop = padding && padding !== 0 ? padding : 0,
  paddingBottom = padding && padding !== 0 ? padding : 0,
  paddingLeft = padding && padding !== 0 ? padding : 0,
  paddingRight = padding && padding !== 0 ? padding : 0,
  height = null,
  minHeight = undefined,
  ...props
}) => {

  const styles = {
    image: {
      width: width,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      height: height,
      minHeight: minHeight,
      resizemode: 'contain',
      flex: 1,
    }
  };

  return (
    <img
      style={styles.image}
      // srcSet={`${url}?w=161&fit=crop&auto=format&dpr=2 2x`}
      src={`${url}`}
      alt={title}
      loading="lazy"
    />
  )
};

export default ImageProp;
