import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import {
  Alert,
  Loading,
  Container,
  FormColumn,
  Dialog,
} from '../../components';
import { useNavigate } from "react-router-dom";
import faceApiService from '../../services/faceApiService';

import LawInstruction from './LawInstruction';
import PreForm from './PreForm';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const DeleteEventDataPage = (props) => {

  const navigate = useNavigate();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: ''
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [preForm, setPreForm] = React.useState({
    eventId: '',
    system: '',
  });
  const [dialogDeleteDataControl, setDialogDeleteDataControl] = React.useState({
    open: false,
    title: '',
    message: '',
    command: '', // trySyncDataAndExit, exitApp
  });

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    if (!store.getState().user.id || !store.getState().user.email) {
      navigate('/Login', { replace: true });
    }
  }, []);

  const openLawArticle = React.useCallback(() => {
    window.open('https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm', '_blank');
  }, []);

  const openDialogDeleteData = React.useCallback(() => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("carregando") });

      let _errorMessage = validateForm();
      if (_errorMessage) {
        setAlertControl({
          open: true,
          title: '',
          message: _errorMessage,
          icon: 'danger'
        });
        return;
      }

      setDialogDeleteDataControl({
        ...dialogDeleteDataControl,
        open: true,
        title: '',
        message: `${getDic("confirmacao-de-acao")}`,
        command: 'delete'
      });

    } catch (error) {
      console.log('Error openDialogDeleteData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function validateForm() {
      if (!preForm.eventId) {
        return `${getDic("campo")} "ID ${getDic("evento")}" ${getDic("obrigatorio")}`;
      }
      if (!preForm.system) {
        return `${getDic("campo")} "${getDic("sistema")}" ${getDic("obrigatorio")}`;
      }
    }
  }, [preForm]);

  const tryDeleteData = React.useCallback(async () => {
    try {
      setDialogDeleteDataControl({
        ...dialogDeleteDataControl,
        open: false,
      });

      setLoadingControl({ ...loadingControl, open: true, message: `${getDic("deletar")} ${getDic("dados")}` });

      await faceApiService.deleteFaceDetectionsByEvent(preForm.eventId, preForm.system);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success'
      });

    } catch (error) {
      console.log('Error tryDeleteData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  }, [preForm]);

  return (
    <Container
      header={`${getDic("deletar")} ${getDic("dados")}`}
      padding={10}
      backButton
    >
      <FormColumn padding={5} alignSelf='center'>
        <LawInstruction
          dimensions={dimensions}
          openLawArticle={openLawArticle}
        />
        <PreForm
          dimensions={dimensions}
          preForm={preForm}
          setPreForm={setPreForm}
          openDialogDeleteData={openDialogDeleteData}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogDeleteDataControl.open) {
      return (
        <Dialog
          open={dialogDeleteDataControl.open}
          title={dialogDeleteDataControl.title}
          message={dialogDeleteDataControl.message}
          icon='danger'
          button1OnPress={() => tryDeleteData()}
          button2OnPress={() => setDialogDeleteDataControl({ ...dialogDeleteDataControl, open: false })}
          backdropPress={() => setDialogDeleteDataControl({ ...dialogDeleteDataControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default DeleteEventDataPage;
