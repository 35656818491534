import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextSmall,
} from '../../components';

const EmbedInstructions = (props) => {
  const {
    dimensions,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={width} padding={10}>
        <FormRow>
          <TextSmall fontWeight='bold'>
            Instruções para Embedar:
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`- Utilizar a url https://face-recognition-app.sistemasinteegra.com.br/FaceScan?public=true&eventId={EVENTOID}&system={SISTEMA}`}
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`- Substitua a tag {EVENTOID} pelo ID do evento que será utilizado`}
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`- Substitua a tag {SISTEMA} por uma das opções listadas de acordo com o local onde os dados do participante estão salvos: mice, mice_sant, inteegra_eventos`}
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`- Configurações Opcionais:`}
          </TextSmall>
        </FormRow>
        <FormRow>
          <TextSmall>
            {`- 1) Para utilizar a câmera traseira adicione o parâmetro useBackCamera=true ao path.`}
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`- Ao Dev: Após capturar a imagem, será emitido uma mensagem via window.top.postmessage com os dados em json {
              message: 'faceDetected',
              data: {
                guestId: detection._label,
                eventId: faceScanControl.eventId,
                system: faceScanControl.system,
              }
            }`}
          </TextSmall>
        </FormRow>
      </FormColumn>
    </FormRow>
  )
};

export default EmbedInstructions;
