import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextSmall,
  CameraScan,
} from '../../components';

const CameraScanProp = (props) => {
  const {
    params,
    dimensions,
    tryDetectFace,
    labeledFaceDescriptors,
  } = props;

  const width = dimensions.width > 660 ? 640 : dimensions.width - (params?.public === 'true' ? 0 : 50);
  const height = dimensions.height > 500 ? 480 : dimensions.height - (params?.public === 'true' ? 40 : 70);

  return (
    <FormColumn
      height={dimensions.height - (params?.public === 'true' ? 1 : 70)}
      justifyContent='center'
    >
      <FormRow justifyContent='center' paddingTop={5}>
        <TextSmall align='center'>
          {`- Posicione seu rosto dentro da marcação`}
        </TextSmall>
      </FormRow>
      <FormRow justifyContent='center'>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            alignSelf: 'center',
            justifyContent: 'center',
            height: 250,
            width: 200,
            border: '15px solid white',
            borderRadius: 150,
            opacity: 0.6
          }}
        />
        <CameraScan
          width={width}
          height={height}
          onFaceDetect={i => tryDetectFace(i)}
          labeledFaceDescriptors={labeledFaceDescriptors}
          useBackCamera={params?.useBackCamera === 'true' ? true : false}
        />
      </FormRow>
    </FormColumn>
  )
};

export default CameraScanProp;
