import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const IsLoadingBackdrop = () => {

  const styles = {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    indicator: {
      color: 'black'
    }
  };

  return (
    <Box style={styles.container}>
      <CircularProgress style={styles.indicator} />
    </Box>
  )
};

export default IsLoadingBackdrop;
