import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  CameraScanExpressions,
  Card,
  TextMedium,
  TextSmall,
} from '../../components';

const CameraScanProp = (props) => {
  const {
    params,
    dimensions,
    tryDetectFace,
    faceScanControl,
    expressions,
  } = props;

  const width = dimensions.width > 660 ? 640 : dimensions.width - (params?.public === 'true' ? 0 : 50);
  const height = dimensions.height > 500 ? 480 : dimensions.height - (params?.public === 'true' ? 40 : 70);

  return (
    <FormColumn
      height={dimensions.height - (params?.public === 'true' ? 1 : 70)}
      justifyContent='center'
    >
      {renderEventData()}
      {renderNumbers()}
      <FormRow justifyContent='center'>
        <CameraScanExpressions
          width={width}
          height={height}
          onFaceDetect={i => tryDetectFace(i)}
          useBackCamera={params?.useBackCamera === 'true' ? true : false}
          scanTime={5000}
        />
      </FormRow>
    </FormColumn>
  )

  function renderEventData() {
    return (
      <FormRow justifyContent='center' padding={5}>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextMedium>ID {getDic("evento")}</TextMedium>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextMedium>
                {faceScanControl.eventId}
              </TextMedium>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextMedium>{getDic("sistema")}</TextMedium>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextMedium>
                {faceScanControl.system}
              </TextMedium>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextMedium>ID {getDic("programacao")}</TextMedium>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextMedium>
                {faceScanControl.scheduleId}
              </TextMedium>
            </FormRow>
          </Card>
        </FormColumn>
      </FormRow>
    )
  }

  function renderNumbers() {
    return (
      <FormRow justifyContent='center' padding={5}>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall fontWeight='bold'>Total</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall fontWeight='bold'>
                {expressions.amount}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Neutro</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.neutral}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Feliz</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.happy}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Triste</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.sad}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Nervoso</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.angry}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Com Medo</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.fearful}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Com Nojo</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.disgusted}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
        <FormColumn padding={5}>
          <Card padding={5} borderRadius={5}>
            <FormRow justifyContent='center'>
              <TextSmall>Surpreso</TextSmall>
            </FormRow>
            <FormRow justifyContent='center'>
              <TextSmall>
                {expressions.surprised}
              </TextSmall>
            </FormRow>
          </Card>
        </FormColumn>
      </FormRow>
    )
  }
};

export default CameraScanProp;
