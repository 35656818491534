import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Alert,
  Loading,
  Container,
} from '../../components';
import { useNavigate } from "react-router-dom";
import expressionDetectionService from '../../services/expressionDetectionService';
import urlUtils from '../../utils/urlUtils';
import ExpressionsDetectionModel from '../../models/ExpressionsDetectionModel';
import moment from 'moment';
import { useDeviceData } from 'react-device-detect';

import CameraScan from './CameraScan';
import EmbedInstructions from './EmbedInstructions';
import PreForm from './PreForm';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const ExpressionRecognitionPage = (props) => {

  const navigate = useNavigate();
  const params = urlUtils.getSearchParams();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [faceScanControl, setFaceScanControl] = React.useState({
    eventId: '',
    system: '',
    scheduleId: '',
    openPreForm: false,
    openCamera: false,
  });
  const [expressions, setExpressions] = React.useState(ExpressionsDetectionModel());
  const deviceData = useDeviceData(window.navigator.userAgent)

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if ((!store.getState().user.id || !store.getState().user.email) && params?.public !== 'true') {
        navigate('/Login', { replace: true });
      }

      await expressionDetectionService.loadModels();

      await getPreForm();

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    async function getPreForm() {
      try {
        let _faceScanControl = { ...faceScanControl };

        if (
          params?.public === 'true' && (
            !params?.eventId ||
            !params?.system ||
            !params?.scheduleId
          )) {
          navigate('/Login', { replace: true });
        }

        if (params?.eventId) {
          _faceScanControl.eventId = params.eventId;
        }
        if (params?.system) {
          _faceScanControl.system = params.system;
        }
        if (params?.scheduleId) {
          _faceScanControl.scheduleId = params.scheduleId;
        }

        if (!_faceScanControl.eventId || !_faceScanControl.system || !_faceScanControl.scheduleId) {
          _faceScanControl.openPreForm = true;
        } else {
          _faceScanControl.openCamera = true;
        }

        setFaceScanControl(_faceScanControl);

      } catch (error) {
        console.log('Error getPreForm', error);
        throw error;
      }
    }
  }, []);

  const tryOpenCamera = React.useCallback(async () => {
    try {
      let _errorMessage = validateForm();
      if (_errorMessage) {
        setAlertControl({
          open: true,
          title: '',
          message: _errorMessage,
          icon: 'danger'
        });
        return;
      }

      setFaceScanControl({
        ...faceScanControl,
        openPreForm: false,
        openCamera: true,
      });

    } catch (error) {
      console.log('Error tryOpenCamera', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    }

    function validateForm() {
      if (!faceScanControl.eventId) {
        return `${getDic("campo")} "ID ${getDic("evento")}" ${getDic("obrigatorio")}`;
      }
      if (!faceScanControl.system) {
        return `${getDic("campo")} "${getDic("sistema")}" ${getDic("obrigatorio")}`;
      }
      if (!faceScanControl.scheduleId) {
        return `${getDic("campo")} "ID ${getDic("programacao")}" ${getDic("obrigatorio")}`;
      }
    }

  }, [faceScanControl]);

  const tryDetectFace = React.useCallback(async (detection) => {
    try {
      let _expressions = ExpressionsDetectionModel({
        ...detection,
        eventId: faceScanControl.eventId,
        system: faceScanControl.system,
        scheduleId: faceScanControl.scheduleId,
        date: moment().toISOString(),
        macAddress: `OS: ${deviceData.os.name} ${deviceData.os.version}`,
      });

      setExpressions(_expressions);

      window.top.postMessage(JSON.stringify({
        message: 'expressionsDetected',
        data: _expressions
      }), '*');

      if (_expressions.amount <= 0) {
        return;
      }

      await expressionDetectionService.saveExpressionDetection(_expressions);

    } catch (error) {
      console.log('Error tryDetectFace', error);
    }
  }, [faceScanControl]);

  return (
    <Container
      header={params?.public === 'true' ? null : `${getDic("reconhecimento-expressao-facial")}`}
      padding={params?.public === 'true' ? 0 : 10}
      backButton={params?.public === 'true' ? false : true}
    >
      {params?.public !== 'true' && !faceScanControl.openCamera && (
        <EmbedInstructions
          dimensions={dimensions}
        />
      )}
      {faceScanControl.openPreForm && (
        <PreForm
          dimensions={dimensions}
          faceScanControl={faceScanControl}
          setFaceScanControl={setFaceScanControl}
          tryOpenCamera={tryOpenCamera}
        />
      )}
      {faceScanControl.openCamera && (
        <CameraScan
          params={params}
          dimensions={dimensions}
          tryDetectFace={tryDetectFace}
          faceScanControl={faceScanControl}
          expressions={expressions}
        />
      )}

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default ExpressionRecognitionPage;
