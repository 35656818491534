import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ButtonTransparent from './ButtonTransparent';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const DialogProp = ({
  children,
  open,
  title = "",
  message = "",
  button1Text = "",
  button1OnPress,
  button2Text = "",
  button2OnPress,
  button3Text = "",
  button3OnPress,
  backdropPress,
  icon = 'info', // success, error, danger, info
  ...props
}) => {

  const [dimensions, setDimensions] = React.useState(getCurrentDimension());

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  const styles = {
    window: {
      backgroundColor: 'white',
      minWidth: 150,
      borderRadius: 15,
      maxWidth: '80%',
      padding: 15,
      elevation: 20,
      shadowColor: '#52006A',
      fleDirection: 'column',
      boxShadow: '0px 0px 10px #3b3a3a',
    },
    messageView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 10,
      width: '100%',
      flexDirection: 'column',
    },
    buttonView: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      right: 0,
      flexDirection: 'row-reverse'
    },
    button: {
      minWidth: 30,
      minHeight: 20,
      paddingLeft: 15,
      paddingRight: 15
    },
    buttonText: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 16,
      fontWeight: 'bold',
      paddingLeft: 5,
      paddingRight: 5
    },
    textTitle: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10
    },
    textMessage: {
      fontFamily: 'Verdana',
      color: '#404040',
    },
    icon: {
      display: 'flex',
      marginTop: -40,
      backgroundColor: 'white',
      borderRadius: 50,
      width: 62,
      alignItems: 'center',
      justifyContent: 'center',
    }
  };

  const localDialogPress = (e) => {
    if (backdropPress) {
      backdropPress(e);
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={localDialogPress}
    >
      <Box height={`${dimensions.height}px`} display='flex' justifyContent='center' alignItems='center'>
        <Box style={styles.backdrop}>
          <Box style={styles.window}>
            <Box style={styles.messageView}>
              {renderIcon()}
              {title !== '' && (
                <Box style={styles.textTitle}>{title}</Box>
              )}
              {message !== '' && (
                <Box style={styles.textMessage}>{message}</Box>
              )}
            </Box>
            {children && (
              <Box>
                {children}
              </Box>
            )}
            {button1OnPress && (
              <Box style={styles.buttonView}>
                {button1OnPress && (
                  <ButtonTransparent onPress={button1OnPress}>
                    <Box style={styles.buttonText}>{button1Text ? button1Text : getDic("sim")}</Box>
                  </ButtonTransparent>
                )}
                {button2OnPress && (
                  <ButtonTransparent onPress={button2OnPress}>
                    <Box style={styles.buttonText}>{button2Text ? button2Text : getDic("nao")}</Box>
                  </ButtonTransparent>
                )}
                {button3OnPress && (
                  <ButtonTransparent onPress={button3OnPress}>
                    <Box style={styles.buttonText}>{button3Text ? button3Text : getDic("cancelar")}</Box>
                  </ButtonTransparent>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )

  function renderIcon() {
    if (icon === 'success') {
      return (
        <Box style={styles.icon}>
          <CheckCircleIcon style={{ fontSize: 60, color: "green" }} />
        </Box>
      )
    } else if (icon === 'error') {
      return (
        <Box style={styles.icon}>
          <CancelIcon style={{ fontSize: 60, color: "red" }} />
        </Box>
      )
    } else if (icon === 'danger') {
      return (
        <Box style={styles.icon}>
          <ErrorIcon style={{ fontSize: 60, color: "orange" }} />
        </Box>
      )
    } else {
      return (
        <Box style={styles.icon}>
          <InfoIcon style={{ fontSize: 60, color: "#0072A3" }} />
        </Box>
      )
    }
  }
};

export default DialogProp;
