import axios from 'axios';
import { store } from '../store';
import * as actions from '../store/actions';
import UserModel from '../models/UserModel';
import moment from 'moment';

export default {
  getUrl() {
    if (window?.location?.host === 'localhost:3000') {
      return {
        apiType: 'dev',
        // faceRecognition: 'http://localhost:3100/',
        faceRecognition: 'https://face-recognition-api.sistemasinteegra.com.br/',
      }
    }

    return {
      apiType: 'inteegra',
      faceRecognition: 'https://face-recognition-api.sistemasinteegra.com.br/',
    }
  },

  async getUserToken() {
    let user = UserModel(store.getState().user);
    let url = this.getUrl();
    try {
      if (checkIfTokenIsUsable()) {
        return user.token;
      }

      let _ret = await refreshToken();
      user.token = _ret.token;
      user.refreshToken = _ret.refreshToken;
      user.lastTokenDate = moment().format();
      store.dispatch(actions.setUser(user));
      return _ret.token;
    } catch (error) {
      console.log('Error getUserToken', error);
      throw error;
    }

    function checkIfTokenIsUsable() {
      try {
        if (!user.lastTokenDate) { return false; }

        let _currentDate = moment().format();
        let _lastDate = moment(user.lastTokenDate).add(30, 'minutes').format();

        if (_currentDate > _lastDate) {
          return false;
        }
        return true;
      } catch (error) {
        console.log('Error checkIfTokenIsUsable', error);
        throw error;
      }
    }

    async function refreshToken() {
      try {
        const _options = {
          url: `${url.faceRecognition}loginAdmin/refreshtoken`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            refreshToken: user.refreshToken,
          }
        };

        const _ret = await axios(_options);
        return _ret.data.data;

      } catch (error) {
        console.log('Error refreshToken', error);
        throw error;
      }
    }
  }
}
