var context = typeof window === "undefined" ? global : window;
const FLAG_TYPED_ARRAY = "FLAG_TYPED_ARRAY";

export default {
  stringify(obj) {
    var _string = JSON.stringify(obj, function (key, value) {
      if (value instanceof Int8Array ||
        value instanceof Uint8Array ||
        value instanceof Uint8ClampedArray ||
        value instanceof Int16Array ||
        value instanceof Uint16Array ||
        value instanceof Int32Array ||
        value instanceof Uint32Array ||
        value instanceof Float32Array ||
        value instanceof Float64Array) {
        var replacement = {
          constructor: value.constructor.name,
          data: Array.apply([], value),
          flag: FLAG_TYPED_ARRAY
        }
        return replacement;
      }
      return value;
    });
    return _string;
  },

  parse(jsonStr = '') {
    var _decodedJson = JSON.parse(jsonStr, function (key, value) {
      try {
        if ("flag" in value && value.flag === FLAG_TYPED_ARRAY) {
          return new context[value.constructor](value.data);
        }
      } catch (e) { }

      return value;
    });
    return _decodedJson;
  }
}
