const obj = {
  id: '',
  email: '',
  name: '',
  profile: '',
  token: '',
  refreshToken: '',
  lastTokenDate: '',
}

export default function UserModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
