import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextSmall,
  ButtonTransparent,
} from '../../components';

const EmbedInstructions = (props) => {
  const {
    dimensions,
    openLawArticle,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={width} padding={10}>
        <FormRow>
          <TextSmall fontWeight='bold'>
            LGPD:
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`A Lei Geral de Proteção ao Dado define que os dados de mapeamento de rosto são considerados sensíveis, logo eles devem ser excluídos da base após o término do evento.`}
          </TextSmall>
        </FormRow>
        <FormRow paddingTop={5}>
          <ButtonTransparent onPress={openLawArticle}>
            <TextSmall fontWeight='bold'>
              {`Ler artigo da Lei nº 13.709/2018.`}
            </TextSmall>
          </ButtonTransparent>
        </FormRow>
        <FormRow paddingTop={5}>
          <TextSmall>
            {`Ao confirmar o evento, os dados serão deletados permanentemente.`}
          </TextSmall>
        </FormRow>
      </FormColumn>
    </FormRow>
  )
};

export default EmbedInstructions;
