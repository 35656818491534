import React from "react";
import { getDic } from '../assets/i18n/dictionary';
import { store } from '../store';
import Box from '@mui/material/Box';
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import Alert from './Alert';
import Loading from './Loading';
import Dialog from './Dialog';
import ButtonTransparent from "./ButtonTransparent";
import Modal from '@mui/material/Modal';
import * as Icons from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const SideMenu = ({
  open,
  onClose,
  ...props
}) => {

  const navigate = useNavigate();
  const menuMinWidth = 300;
  const poweredByInteegraHeight = 40;
  const [dimensions, setDimensions] = React.useState({ menuWidth: 0, backdropWidth: 0, menuHeight: 0 });
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [dialogExitAppControl, setDialogExitAppControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
    command: '', // trySyncDataAndExit, exitApp
  });

  React.useEffect(() => {

  }, []);

  React.useEffect(() => {
    const setMenuWidth = (width = 0, height = 0) => {
      let _backdropWidth = width - menuMinWidth
      setDimensions({
        menuWidth: width < menuMinWidth ? width : menuMinWidth,
        backdropWidth: _backdropWidth < 0 ? 0 : _backdropWidth,
        menuHeight: height - poweredByInteegraHeight
      });
    };
    window.addEventListener('resize', setMenuWidth(window.innerWidth, window.innerHeight));
    return (() => {
      window.removeEventListener('resize', setMenuWidth(window.innerWidth, window.innerHeight));
    })
  }, []);

  const closeMenu = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, []);

  const styles = {
    container: {
      justifyContent: "center",
      alignItems: "center",
      margin: 0
    },
    backdrop: {
      height: '100%',
      width: dimensions.backdropWidth,
    },
    window: {
      width: '100%',
      height: '100%',
      backgroundColor: '#456091',
      borderRadius: 5,
      padding: 10,
      boxShadow: '0px 0px 10px #3b3a3a',
    },
    windowButtons: {
      width: '100%',
      height: dimensions.menuHeight,
    },
    poweredByInteegra: {
      display: 'flex',
      height: poweredByInteegraHeight,
      flex: 1,
      justifyContent: "center",
    },
    text: {
      fontFamily: 'Verdana',
      color: 'white',
      fontSize: 17
    },
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onClose}
      >
        <Box style={styles.container}>
          <FormRow>
            <FormColumn width={dimensions.menuWidth}>
              <Box style={styles.window}>
                <Box style={styles.windowButtons}>
                  <FormRow justifyContent='flex-end'>
                    <ButtonTransparent padding={10} onPress={() => closeMenu()}>
                      <Icons.Close style={{ color: 'white' }} />
                    </ButtonTransparent>
                  </FormRow>
                  <ButtonTransparent padding={5} onPress={() => navigate('/Home')}>
                    <FormRow>
                      <Box style={{ paddingRight: 10 }}>
                        <Icons.Home style={{ color: 'white' }} />
                      </Box>
                      <Box style={styles.text}>{getDic("home")}</Box>
                    </FormRow>
                  </ButtonTransparent>
                  <ButtonTransparent padding={5} onPress={() => {
                    setDialogExitAppControl({
                      ...dialogExitAppControl,
                      open: true,
                      title: '',
                      icon: 'info',
                      message: `${getDic("deseja-sair")}`,
                      command: 'trySyncDataAndExit'
                    });
                  }}>
                    <FormRow>
                      <Box style={{ paddingRight: 10 }}>
                        <Icons.ExitToApp style={{ color: 'white' }} />
                      </Box>
                      <Box style={styles.text}>{getDic("sair")}</Box>
                    </FormRow>
                  </ButtonTransparent>
                </Box>
                <Box style={styles.poweredByInteegra}>
                  <Box style={styles.text}>Powered by Inteegra</Box>
                </Box>
              </Box>
            </FormColumn>
            <FormColumn width={dimensions.backdropWidth}>
              <Box
                onClick={() => {
                  closeMenu()
                }}
                style={styles.backdrop}
              >
                <Box style={styles.backdrop}></Box>
              </Box>
            </FormColumn>
          </FormRow>
        </Box>
      </Modal >

      {modalControl()}
    </>
  )

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogExitAppControl.open) {
      return (
        <Dialog
          open={dialogExitAppControl.open}
          title={dialogExitAppControl.title}
          message={dialogExitAppControl.message}
          icon={dialogExitAppControl.icon}
          button1OnPress={() => navigate('/LoginPage', { replace: true })}
          button2OnPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
          backdropPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default SideMenu;
