import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import DeleteEventDataPage from "./pages/DeleteEventDataPage";
import ExpressionRecognitionPage from "./pages/ExpressionRecognitionPage";
import ExpressionReportPage from "./pages/ExpressionReportPage";
import FaceCollectorByPhotoPage from "./pages/FaceCollectorByPhotoPage";
import FaceCollectorPage from "./pages/FaceCollectorPage";
import FaceScanPage from "./pages/FaceScanPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";

const RoutesProp = (props) => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/Login" replace={true} />} />
        <Route path="/DeleteEventData" element={<DeleteEventDataPage />} />
        <Route path="/ExpressionRecognition" element={<ExpressionRecognitionPage />} />
        <Route path="/ExpressionReport" element={<ExpressionReportPage />} />
        <Route path="/FaceCollectorByPhoto" element={<FaceCollectorByPhotoPage />} />
        <Route path="/FaceCollector" element={<FaceCollectorPage />} />
        <Route path="/FaceScan" element={<FaceScanPage />} />
        <Route path="/Home" element={<HomePage />} />
        <Route index path="/Login" element={<LoginPage />} />
        <Route path="/RecoverPassword" element={<RecoverPasswordPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesProp;
