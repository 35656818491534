import React from "react";
import Box from '@mui/material/Box';
import ButtonTransparent from "./ButtonTransparent";
import Modal from '@mui/material/Modal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const SelectField = ({
  value,
  menuItens = [{ value: false, text: 'Não' }, { value: true, text: 'Sim' }],
  title = '',
  onChange,
  onFocus,
  error = false,
  errorMessage = "",
  required = false,
  backgroundColor = "white",
  disabled = false,
  onBlur,
  onPress,
  width = '100%',
  maxWidth = undefined,
  labelColor = 'black',
  inputTextColor = 'black',
  borderColor = '#8f8f8f',
  fieldHeight = 20,
  fieldFontSize = 12,
  labelFontSize = 12,
  iconSize = 18,
  ...props
}) => {

  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [focusBorder, setFocusBorder] = React.useState(false);
  const [openModalItems, setOpenModalItems] = React.useState(false);

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getSelectedValue();
  }, [value]);

  const getSelectedValue = React.useCallback(() => {
    const _selectedValue = menuItens.find((i) => { return i.value === value });
    if (_selectedValue) {
      setSelectedValue(_selectedValue.text);
    }
    setIsLoading(false);
  }, [value]);

  const localOnPress = React.useCallback((e) => {
    setOpenModalItems(true);
    if (onPress) {
      onPress(e);
    }
  }, []);

  const styles = {
    container: {
      paddingTop: 5,
      paddingBottom: 5,
      minHeight: 45,
      width: width,
      maxWidth: maxWidth,
    },
    text: {
      fontFamily: 'Verdana',
      color: labelColor,
      fontSize: labelFontSize,
    },
    inputText: {
      fontFamily: 'Verdana',
      color: inputTextColor
    },
    input: {
      display: 'flex',
      opacity: focusBorder ? 1 : 0.7,
      fontSize: fieldFontSize,
      width: '100%',
      borderRadius: 5,
      fontFamily: 'Verdana',
      alignItems: 'center',
    },
    errorMessage: {
      color: 'red',
      fontSize: fieldFontSize
    },
    searchSection: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: backgroundColor,
      height: fieldHeight,
      padding: 5,
      fontSize: fieldFontSize,
      borderRadius: 5,
      boxShadow: `0px 0px ${focusBorder ? '5px' : '2px'} ${error ? 'red' : borderColor}`,
    },
    modal: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      backgroundColor: 'white',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      minHeight: 100,
      minWidth: 200,
      width: '100%',
      padding: 15,
      elevation: 20,
      shadowColor: '#52006A',
    },
    messageView: {
      alignItems: 'center',
      paddingBottom: 10
    },
    buttonView: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end',
      right: 0,
      paddingTop: 10
    },
    button: {
      alignSelf: 'flex-end',
      width: 30,
      height: 20
    },
    buttonText: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 16,
      fontWeight: 'bold'
    },
    textTitle: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10
    },
    textMessage: {
      fontFamily: 'Verdana',
      color: '#404040',
    },
    listItemContainer: {
      width: '90%',
      minHeight: 40,
      alignItems: 'center',
      alignSelf: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 30,
      paddingBottom: 10,
    },
    listItemText: {
      fontFamily: 'Verdana',
      fontSize: 16,
    }
  };

  return (
    <Box
      style={styles.container}
    >
      {title !== '' && (
        <Box style={styles.text}>{title}</Box>
      )}
      <ButtonTransparent onPress={() => {
        localOnPress();
        setFocusBorder(true);
        if (onFocus) {
          onFocus()
        }
      }}>
        <Box style={styles.searchSection} >
          {!isLoading && (
            <Box style={styles.input}>
              {selectedValue}
            </Box>
          )}
          <ArrowDropDownIcon style={{ color: styles.inputText.color }} />
        </Box>
      </ButtonTransparent>
      {error && errorMessage !== '' && (
        <Box style={styles.errorMessage}>{errorMessage}</Box>
      )}

      <Modal
        open={openModalItems}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box height={`${dimensions.height}px`} display='flex' justifyContent='center' alignItems='center'>
          <Box>
            <Box style={styles.window}>
              {menuItens.length > 0 && menuItens.map((item, i) => {
                return (
                  <Box key={i}>
                    <ButtonTransparent onPress={() => {
                      if (onChange) {
                        onChange(item.value);
                      }
                    }}>
                      <Box style={styles.listItemContainer} >
                        <Box style={{ padding: 10 }}>
                          {item.value === value ? (
                            <CheckCircleIcon style={{ color: 'blue' }} />
                          ) : (
                            <CircleOutlinedIcon style={{ color: 'black' }} />
                          )}
                        </Box>
                        <Box style={styles.listItemText}>{item.text || ''}</Box>
                      </Box>
                    </ButtonTransparent>
                  </Box>
                )
              })}
              <Box style={styles.buttonView}>
                <ButtonTransparent onPress={() => {
                  setOpenModalItems(false)
                  setFocusBorder(false);
                  if (onBlur) {
                    onBlur()
                  }
                }}>
                  <Box style={styles.button}>
                    <Box style={styles.buttonText}>OK</Box>
                  </Box>
                </ButtonTransparent>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SelectField;
