import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  InputField,
  SelectField,
  Button,
  TextSmall,
} from '../../components';
import * as Icons from '@mui/icons-material';

const SendImageForm = (props) => {
  const {
    dimensions,
    faceScanControl,
    setFaceScanControl,
    tryCaptureFace,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={width} padding={10}>
        {renderGuestForm()}
        <FormRow>
          <InputField
            title={`${getDic("arquivo")}`}
            value={faceScanControl.file.value || ''}
            onChange={v => setFaceScanControl({ ...faceScanControl, file: v })}
            type='file'
            accept="image/png, image/jpeg"
          />
        </FormRow>
        <FormRow paddingTop={20} justifyContent='center'>
          <Button
            width={width - 20}
            onPress={() => tryCaptureFace()}
          >
            <FormRow justifyContent='center'>
              <FormColumn justifyContent='center' paddingRight={5}>
                <TextSmall color='white' fontWeight='bold'>{`${getDic("enviar")} ${getDic("imagem")}`}</TextSmall>
              </FormColumn>
              <Icons.CameraAlt style={{ color: 'white', fontSize: 15 }} />
            </FormRow>
          </Button>
        </FormRow>
      </FormColumn>
    </FormRow>
  )

  function renderGuestForm() {
    if (faceScanControl.hasGuestInformation) {
      return null;
    }

    return (
      <>
        <FormRow>
          <InputField
            title={`ID ${getDic("evento")}`}
            value={faceScanControl.eventId}
            onChange={v => setFaceScanControl({ ...faceScanControl, eventId: v })}
          />
        </FormRow>
        <FormRow>
          <SelectField
            title={`${getDic("sistema")}`}
            value={faceScanControl.system}
            onChange={v => setFaceScanControl({ ...faceScanControl, system: v })}
            menuItens={[
              { value: '', text: getDic("selecione") },
              { value: 'inteegra_eventos', text: 'Inteegra Eventos' },
              { value: 'mice', text: 'Mice' },
              { value: 'mice_sant', text: 'Mice Sant' },
            ]}
          />
        </FormRow>
        <FormRow>
          <InputField
            title={`ID ${getDic("participante")}`}
            value={faceScanControl.guestId}
            onChange={v => setFaceScanControl({ ...faceScanControl, guestId: v })}
          />
        </FormRow>
        <FormRow>
          <InputField
            title={`Score`}
            value={faceScanControl.score || ''}
            onChange={v => setFaceScanControl({ ...faceScanControl, score: parseInt(v) })}
            onBlur={() => {
              if (faceScanControl.score < 50) {
                setFaceScanControl({ ...faceScanControl, score: 50 })
              }
              if (!faceScanControl.score || faceScanControl.score > 95) {
                setFaceScanControl({ ...faceScanControl, score: 95 })
              }
            }}
            maxLength={2}
          />
        </FormRow>
      </>
    )
  }
};

export default SendImageForm;
