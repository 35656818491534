
export default {
  getSearchParams() {
    const searchParams = new URLSearchParams(window.location.search);
    let _params = {};
    for (const entry of searchParams) {
      _params = { ..._params, [entry[0]]: entry[1] }
    }
    return _params;
  }
}
