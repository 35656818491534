import React, { useCallback, useRef, useState } from "react";
import { getDic } from '../assets/i18n/dictionary';
import Webcam from "react-webcam";
import Button from './Button';
import FormRow from "./FormRow";
import FormColumn from "./FormColumn";
import Box from '@mui/material/Box';

const Camera = ({
  onPhoto,
  width,
  height,
  ...props
}) => {

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (onPhoto) {
      onPhoto(imageSrc);
    }
  }, [webcamRef]);

  return (
    <FormColumn>
      <FormRow>
        <div
          style={{
            width: width || 640,
            height: height || 480,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              justifyContent: 'center',
              height: 250,
              width: 200,
              border: '15px solid white',
              borderRadius: 150,
              opacity: 0.6
            }}
          />
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: width || 640,
              height: height || 480,
              facingMode: "user",
            }}
            minScreenshotWidth={180}
            minScreenshotHeight={180}
            mirrored={false}
          />
        </div>
      </FormRow>
      <FormRow paddingTop={10}>
        <Button
          text={`${getDic("tirar")} ${getDic("foto")}`}
          onPress={capture}
        />
      </FormRow>
    </FormColumn>
  );
};

export default Camera;
