import React, { useCallback, useRef, useState } from "react";
import * as faceapi from 'face-api.js';
import { getDic } from '../assets/i18n/dictionary';
import FormRow from "./FormRow";
import FormColumn from "./FormColumn";

const CameraScanExpressions = ({
  onFaceDetect,
  width,
  height,
  useBackCamera = false,
  scanTime = 5000,
  ...props
}) => {

  const videoRef = React.useRef();
  const canvasRef = React.useRef();

  React.useEffect(() => {
    startVideo();
  }, [])

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 300,
          facingMode: useBackCamera ? 'environment' : 'user',
        }
      })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  }

  const handleVideoOnPlay = async () => {
    setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
        const displaySize = {
          width: width || 640,
          height: height || 480
        }

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi
          .detectAllFaces(videoRef.current)
          .withFaceLandmarks()
          .withFaceExpressions();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, width || 640, height || 480);

        const _expressions = [];

        const results = resizedDetections.map((d) => {
          return d.expressions;
        });
        results.forEach((result, i) => {
          const box = resizedDetections[i].detection.box;

          let _expression = {
            label: 'neutral',
            precision: 0,
          };
          for (var key in result) {
            if (result[key] > _expression.precision) {
              _expression = {
                label: key,
                precision: result[key],
              }
            }
          }

          _expressions.push(_expression);

          const drawBox = new faceapi.draw.DrawBox(box, {
            label: _expression.label,
          });
          drawBox.draw(canvasRef.current);
        });

        getReportResult(_expressions);
      }
    }, scanTime)

    function getReportResult(expressions) {
      try {
        let _result = {
          amount: 0,
          neutral: 0, //=> neutro
          happy: 0, //=> feliz
          sad: 0, //=> triste
          angry: 0, //=> nervoso
          fearful: 0, //=> com medo
          disgusted: 0, //=> com nojo
          surprised: 0, //=> surpreso
        }

        for (let i = 0; i < expressions.length; i++) {
          _result.amount += 1;
          _result[expressions[i].label] += 1;
        }

        onFaceDetect(_result);

      } catch (error) {
        console.log('Error getReportResult', error);
      }
    }
  }

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    // setCaptureVideo(false);
  }

  return (
    <FormColumn>
      <FormRow>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            ref={videoRef}
            height={height || 480}
            width={width || 640}
            onPlay={handleVideoOnPlay}
          // style={{
          //   height: `${height || 480}px`,
          //   width: `${width || 640}px`,
          //   // objectFit: 'cover'
          // }}
          />
          <canvas ref={canvasRef} willReadFrequently style={{ position: 'absolute' }} />
        </div>
      </FormRow>
    </FormColumn>
  );
};

export default CameraScanExpressions;
