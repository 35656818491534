import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextMedium,
} from '../../components';

const EndMessage = (props) => {
  const {
    dimensions,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormColumn height={dimensions.height} padding={10} justifyContent='center' alignItems='center'>
      <FormRow width={width} justifyContent='center'>
        <TextMedium fontWeight='bold'>
          {getDic("esta-pagina-pode-ser-fechada")}
        </TextMedium>
      </FormRow>
    </FormColumn>
  )
};

export default EndMessage;
