import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Alert,
  Loading,
  Container,
} from '../../components';
import { useNavigate } from "react-router-dom";
import faceApiService from '../../services/faceApiService';
import urlUtils from '../../utils/urlUtils';
import fileUtils from '../../utils/fileUtils';

import CameraScan from './CameraScan';
import EmbedInstructions from './EmbedInstructions';
import PreForm from './PreForm';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const FaceScanPage = (props) => {

  const navigate = useNavigate();
  const params = urlUtils.getSearchParams();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [faceScanControl, setFaceScanControl] = React.useState({
    eventId: '',
    system: '',
    openPreForm: false,
    openCamera: false,
  });
  const [labeledFaceDescriptors, setLabeledFaceDescriptors] = React.useState();

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if ((!store.getState().user.id || !store.getState().user.email) && params?.public !== 'true') {
        navigate('/Login', { replace: true });
      }

      await faceApiService.loadModels();

      await getTestImageAndTestFaceDetection();

      await getPreForm();

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    async function getTestImageAndTestFaceDetection() {
      let _res = await fetch(require('../../assets/test_face.jpg'));
      let _blob = await _res.blob();
      let _file = new File([_blob], "test_face.jpg", { type: _blob.type });
      let _base64 = await fileUtils.convertFileToBase64(_file);
      await faceApiService.getFaceDetectionFromBase64ImageCapture(_base64);
    }

    async function getPreForm() {
      try {
        let _faceScanControl = { ...faceScanControl };

        if (
          params?.public === 'true' && (
            !params?.eventId ||
            !params?.system
          )) {
          navigate('/Login', { replace: true });
        }

        if (params?.eventId) {
          _faceScanControl.eventId = params.eventId;
        }
        if (params?.system) {
          _faceScanControl.system = params.system;
        }

        if (!_faceScanControl.eventId || !_faceScanControl.system) {
          _faceScanControl.openPreForm = true;
        } else {
          await getEventData(_faceScanControl.eventId, _faceScanControl.system);
          _faceScanControl.openCamera = true;
        }

        setFaceScanControl(_faceScanControl);

      } catch (error) {
        console.log('Error getPreForm', error);
        throw error;
      }
    }
  }, []);

  const tryOpenCamera = React.useCallback(async () => {
    try {
      let _errorMessage = validateForm();
      if (_errorMessage) {
        setAlertControl({
          open: true,
          title: '',
          message: _errorMessage,
          icon: 'danger'
        });
        return;
      }

      await getEventData(faceScanControl.eventId, faceScanControl.system);

      setFaceScanControl({
        ...faceScanControl,
        openPreForm: false,
        openCamera: true,
      });

    } catch (error) {
      console.log('Error tryOpenCamera', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    }

    function validateForm() {
      if (!faceScanControl.eventId) {
        return `${getDic("campo")} "ID ${getDic("evento")}" ${getDic("obrigatorio")}`;
      }
      if (!faceScanControl.system) {
        return `${getDic("campo")} "${getDic("sistema")}" ${getDic("obrigatorio")}`;
      }
    }

  }, [faceScanControl]);

  const getEventData = React.useCallback(async (eventId = '', system = '') => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("carregando") });

      let _detections = await getFaceDetectionsList();

      let _labeledFaceDescriptors = await faceApiService.loadDetections(_detections);

      setLabeledFaceDescriptors(_labeledFaceDescriptors);

    } catch (error) {
      console.log('Error getEventData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    async function getFaceDetectionsList() {
      try {
        let _array = [];

        let _hasMore = true;
        let _offset = 0;
        do {
          let _detections = await faceApiService.getFaceDetections(
            eventId,
            system,
            '',
            {
              limit: 500,
              offset: _offset,
              hasMore: true
            },
            '',
            '',
          );

          _offset += 500;
          _array = [
            ..._array,
            ..._detections.data,
          ];
          _hasMore = _detections.pagination.hasMore;

          // let _progress = (_offset / _detections.count * 100).toFixed(0);
          // setLoadingControl({ ...loadingControl, open: true, message: `${getDic("carregando")} ${_progress > 100 ? 100 : _progress}%` });

        } while (_hasMore);

        return _array;
      } catch (error) {
        console.log('Error getEventData', error);
        throw error;
      }
    }
  }, []);

  const tryDetectFace = React.useCallback((detection) => {
    try {
      window.top.postMessage(JSON.stringify({
        message: 'faceDetected',
        data: {
          guestId: detection._label,
          eventId: faceScanControl.eventId,
          system: faceScanControl.system,
        }
      }), '*');

    } catch (error) {
      console.log('Error tryDetectFace', error);
    }
  }, [faceScanControl]);

  return (
    <Container
      header={params?.public === 'true' ? null : `${getDic("reconhecimento-facial")}`}
      padding={params?.public === 'true' ? 0 : 10}
      backButton={params?.public === 'true' ? false : true}
    >
      {params?.public !== 'true' && !faceScanControl.openCamera && (
        <EmbedInstructions
          dimensions={dimensions}
        />
      )}
      {faceScanControl.openPreForm && (
        <PreForm
          dimensions={dimensions}
          faceScanControl={faceScanControl}
          setFaceScanControl={setFaceScanControl}
          tryOpenCamera={tryOpenCamera}
        />
      )}
      {faceScanControl.openCamera && labeledFaceDescriptors && (
        <CameraScan
          params={params}
          dimensions={dimensions}
          tryDetectFace={tryDetectFace}
          labeledFaceDescriptors={labeledFaceDescriptors}
        />
      )}

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default FaceScanPage;
