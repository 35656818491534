import * as React from 'react';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import userService from '../../services/userService';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  FormColumn,
  Card,
} from '../../components';
import { useNavigate } from "react-router-dom";

import InteegraCopyright from './InteegraCopyright';
import LoginForm from './LoginForm';
import RoundedBackground from './RoundedBackground';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const LoginPage = (props) => {

  const navigate = useNavigate();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [loginForm, setLoginForm] = React.useState({
    login: '',
    password: ''
  });
  const [loginFormError, setLoginFormError] = React.useState({
    login: '',
    password: ''
  });

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {

    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
    setLoadingControl({ ...loadingControl, open: false });
  }, []);

  const tryLogin = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true });

      if (!validateForm()) {
        setLoadingControl({ ...loadingControl, open: false });
        return;
      }

      let _user = await userService.loginUser(loginForm.login, loginForm.password);
      store.dispatch(actions.setUser(_user));

      navigate('/Home', { replace: true });

    } catch (error) {
      console.log("Error tryLogin", error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function errorTreatment(error) {
      let _message = getDic("enviado-erro");

      if (error?.response?.data?.message === 'EMAIL_NOT_FOUND') {
        _message = getDic("falha-login-mensagem");
      }
      if (error?.response?.data?.message === 'WRONG_PASSWORD') {
        _message = getDic("falha-login-mensagem");
      }
      if (error?.response?.data?.message === 'FIRST_ACCESS_REQUIRED') {
        _message = `${getDic("primeiro")} ${getDic("acesso")} ${getDic("nao")} ${getDic("realizado")}`;
      }

      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: _message,
        icon: 'error',
      });
    }

    function validateForm() {
      let _isValid = true;
      let _loginFormError = {
        login: '',
        password: ''
      };

      if (!loginForm.login) {
        _isValid = false;
        _loginFormError.login = getDic("preenchimento-obrigatorio");
      }

      if (!loginForm.password) {
        _isValid = false;
        _loginFormError.password = getDic("preenchimento-obrigatorio");
      }

      setLoginFormError(_loginFormError);

      return _isValid;
    }
  }, [loginForm]);

  const openPageRecoverPassword = React.useCallback(async () => {
    navigate('/RecoverPassword');
  }, []);

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      padding={0}
      scroll
    >
      <RoundedBackground dimensions={dimensions} />
      <FormColumn alignItems='center' paddingTop={70} padding={10}>
        <Card borderRadius={20}>
          <LoginForm
            dimensions={dimensions}
            loginForm={loginForm}
            setLoginForm={setLoginForm}
            loginFormError={loginFormError}
            tryLogin={tryLogin}
            openPageRecoverPassword={openPageRecoverPassword}
          />
        </Card>
        <InteegraCopyright />
      </FormColumn>

      {modalControl()}
    </Container >
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default LoginPage;
