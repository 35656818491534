import React from "react";
import Box from '@mui/material/Box';
import ButtonTransparent from "./ButtonTransparent";
import CloseIcon from '@mui/icons-material/Close';

const InputField = ({
  key,
  value,
  title,
  onChange,
  placeholder = "",
  onFocus,
  error = false,
  errorMessage = "",
  required = false,
  maxLength,
  backgroundColor = "white",
  editable = true,
  onBlur,
  blurIcon = false,
  blurIconPress,
  type = "text",
  accept,
  onPress,
  labelColor = 'black',
  borderColor = '#8f8f8f',
  fieldHeight = 20,
  fieldFontSize = 12,
  labelFontSize = 12,
  iconLeft = null,
  iconRight = null,
  multiline = false,
  ...props
}) => {

  const [focusBorder, setFocusBorder] = React.useState(false);

  const styles = {
    container: {
      paddingTop: 5,
      paddingBottom: 5,
      opacity: editable ? 1 : 0.6,
      flex: 1,
    },
    searchSection: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: backgroundColor,
      height: fieldHeight,
      padding: 5,
      fontSize: fieldFontSize,
      borderRadius: 5,
      boxShadow: `0px 0px ${focusBorder ? '5px' : '2px'} ${error ? 'red' : borderColor}`,
    },
    text: {
      fontFamily: 'Verdana',
      color: labelColor,
      fontSize: labelFontSize
    },
    input: {
      opacity: focusBorder ? 1 : 0.8,
      fontSize: fieldFontSize,
      width: '100%',
      borderRadius: 5,
      border: 'none',
      outline: 'none',
      fontFamily: 'Verdana',
    },
    errorMessage: {
      color: 'red',
      fontSize: fieldFontSize,
      fontFamily: 'Verdana'
    }
  };

  const localOnChange = React.useCallback(e => {
    if (!onChange) { return null }

    if (type === 'file') {
      onChange(e.target);
      return;
    }
    onChange(e.target.value);
  }, [onChange, value]);

  return (
    <Box style={styles.container}>
      <Box style={styles.text}>{title}</Box>
      <Box style={styles.searchSection}>
        {iconLeft}
        <input
          style={styles.input}
          onChange={e => localOnChange(e)}
          value={value}
          placeholder={placeholder}
          type={type}
          accept={accept}
          maxLength={maxLength}
          editable={editable.toString()}
          onFocus={(e) => {
            setFocusBorder(true);
            if (onFocus) {
              onFocus(e)
            }
          }}
          onBlur={(e) => {
            setFocusBorder(false);
            if (onBlur) {
              onBlur(e)
            }
          }}
          multiline={multiline.toString()}
        />
        {blurIcon && (
          <ButtonTransparent onPress={() => {
            if (blurIconPress) {
              blurIconPress();
            }
          }}>
            <CloseIcon fontSize="small" style={{ color: '#8a8a8a' }} />
          </ButtonTransparent>
        )}
        {iconRight}
      </Box>
      {error && errorMessage !== '' && (
        <Box style={styles.errorMessage}>{errorMessage}</Box>
      )}
    </Box>
  );
};

export default InputField;
