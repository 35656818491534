const obj = {
  id: '',
  macAddress: '',
  eventId: '',
  system: '',
  scheduleId: '',
  date: '',
  amount: 0,
  neutral: 0, //=> neutro
  happy: 0, //=> feliz
  sad: 0, //=> triste
  angry: 0, //=> nervoso
  fearful: 0, //=> com medo
  disgusted: 0, //=> com nojo
  surprised: 0, //=> surpreso
}

export default function ExpressionsDetectionModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
