import axios from 'axios';
import UserModel from '../models/UserModel';
import apiService from './apiService';
import moment from 'moment';
import { store } from '../store';
import urlUtils from '../utils/urlUtils';

export default {
  async loginUser(login = '', password = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().faceRecognition}loginAdmin/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: login,
          password: password
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      let _user = getUserModelFromApiReturn(_ret.data);
      return _user;

    } catch (error) {
      console.log('Error getUserApi', error);
      throw error;
    }
  },

  async createTokenRecoverPassword(email = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().faceRecognition}loginAdmin/createTokenRecoveryPassword`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error createTokenRecoverPassword', error);
      throw error;
    }
  },

  async recoverPassword(email = '', token = '', password = '', confirmPassword = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().faceRecognition}loginAdmin/recoverPassword`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email,
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error recoverPassword', error);
      throw error;
    }
  },

  async loginUserByIdPath() {
    try {
      let _user = UserModel(store.getState().user);

      const _params = urlUtils.getSearchParams();
      if (_params?.userId && _params?.userId !== _user.id) {
        let _ret = await axios({
          url: `${apiService.getUrl().faceRecognition}loginAdmin/getUserById`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            userId: _params?.userId,
          }
        });

        if (!_ret.data.success) {
          throw new Error(_ret.data.message);
        }

        _user = getUserModelFromApiReturn(_ret.data);
      }

      return _user;
    } catch (error) {
      console.log('Error loginUserByIdPath', error);
      throw error;
    }
  },
}

function getUserModelFromApiReturn(apiReturn) {
  try {
    let _user = UserModel({
      id: apiReturn.data.user.id,
      email: apiReturn.data.user.email,
      name: apiReturn.data.user.name,
      profile: apiReturn.data.user.profile,
      token: apiReturn.data.token,
      refreshToken: apiReturn.data.refreshToken,
      lastTokenDate: moment().format(),
    });

    return _user;
  } catch (error) {
    console.log('Error getUserModelFromApiReturn', error);
    throw error;
  }
}
