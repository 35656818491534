import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import {
  Alert,
  Loading,
  Container,
  FormColumn,
} from '../../components';
import { useNavigate } from "react-router-dom";

import CommandButtons from './CommandButtons';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const HomePage = (props) => {

  const navigate = useNavigate();
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: ''
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = React.useCallback(async () => {
    if (!store.getState().user.id || !store.getState().user.email) {
      navigate('/Login', { replace: true });
    }
  }, []);

  const navigatePage = React.useCallback((page = '') => {
    navigate(page);
  }, []);

  return (
    <Container
      header='Home'
      padding={10}
      menu
    >
      <FormColumn padding={5} alignSelf='center'>
        <CommandButtons
          dimensions={dimensions}
          navigatePage={navigatePage}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default HomePage;
