import React from 'react';
import {
  FormRow,
  FormColumn,
  Image
} from '../../components';

const InteegraLogoButton = () => {

  return (
    <FormRow justifyContent='center'>
      <FormColumn>
        <Image height={100} width={400} url={require('../../assets/logo-inteegra.png')} />
      </FormColumn>
    </FormRow>
  )
};

export default InteegraLogoButton;
