import React from 'react';
import Box from '@mui/material/Box';

const Card = ({
  children,
  padding = 5,
  background = 'white',
  backgroundImage,
  borderRadius = 15,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderColor,
  borderWidth,
  borderLeftColor,
  borderRightColor,
  borderTopColor,
  borderBottomColor,
  borderLeftWidth,
  borderRightWidth,
  borderTopWidth,
  borderBottomWidth,
  width,
  height,
  resizemode = 'cover',
  ...props
}) => {

  const styles = {
    container: {
      backgroundColor: backgroundImage ? 'transparent' : background || '#ffffff',
      borderTopLeftRadius: borderTopLeftRadius !== undefined ? borderTopLeftRadius : borderRadius,
      borderBottomLeftRadius: borderBottomLeftRadius !== undefined ? borderBottomLeftRadius : borderRadius,
      borderTopRightRadius: borderTopRightRadius !== undefined ? borderTopRightRadius : borderRadius,
      borderBottomRightRadius: borderBottomRightRadius !== undefined ? borderBottomRightRadius : borderRadius,
      padding: padding,
      boxShadow: '0px 0px 5px #3b3a3a',
      justifyContent: "center",
      borderColor: borderColor,
      borderWidth: borderWidth,
      borderStyle: borderWidth ? 'solid' : undefined,
      borderLeftColor: borderLeftColor || borderColor,
      borderRightColor: borderRightColor || borderColor,
      borderTopColor: borderTopColor || borderColor,
      borderBottomColor: borderBottomColor || borderColor,
      borderLeftWidth: borderLeftWidth || borderWidth,
      borderRightWidth: borderRightWidth || borderWidth,
      borderTopWidth: borderTopWidth || borderWidth,
      borderBottomWidth: borderBottomWidth || borderWidth,
      width: width,
      height: height,
    },
    imageBackground: {
      borderRadius: borderRadius,
      flex: 1,
    },
  };

  if (backgroundImage) {
    return (
      <Box
        source={{ uri: backgroundImage }}
        style={styles.imageBackground}
        imageStyle={{ borderRadius: borderRadius, resizemode: resizemode }}
      >
        {renderCard()}
      </Box>
    )
  }
  return renderCard();

  function renderCard() {
    return (
      <Box style={
        styles.container
      }>
        {children}
      </Box>
    )
  }
};

export default Card;
