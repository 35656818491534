import React from 'react';
import Box from '@mui/material/Box';

const TextSmall = ({
  children = '',
  color = "black",
  fontSize = 12,
  fontWeight,
  align,
  ...props
}) => {

  const styles = {
    text: {
      fontSize: fontSize,
      color: color,
      fontWeight: fontWeight,
      fontFamily: 'Verdana',
      textAlign: align
    }
  };

  return (
    <Box style={styles.text}>
      {children}
    </Box>
  )
};

export default TextSmall;
