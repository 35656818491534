import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextSmall,
  Camera,
} from '../../components';

const CameraPhoto = (props) => {
  const {
    params,
    dimensions,
    tryCaptureFace,
  } = props;

  const width = dimensions.width > 660 ? 640 : dimensions.width;
  const height = dimensions.height > 500 ? 480 : dimensions.height;

  return (
    <>
      <FormRow justifyContent='center'>
        <TextSmall align='center'>
          {`- Posicione seu rosto dentro da marcação`}
        </TextSmall>
      </FormRow>
      <FormRow justifyContent='center' paddingTop={5}>
        <TextSmall align='center'>
          {`- Fique em um ambiente bem iluminado`}
        </TextSmall>
      </FormRow>
      <FormRow justifyContent='center' paddingTop={5}>
        <TextSmall align='center'>
          {`- Não use acessórios como óculos, bonés ou algo que cubra parcialmente o rosto`}
        </TextSmall>
      </FormRow>
      <FormRow justifyContent='center' paddingTop={5}>
        <TextSmall align='center'>
          {`- Limpe a lente da câmera e ajuste o foco`}
        </TextSmall>
      </FormRow>
      <FormRow justifyContent='center' paddingTop={10}>
        <Camera
          width={width}
          height={height}
          onPhoto={i => tryCaptureFace(i)}
        />
      </FormRow>
    </>
  )
};

export default CameraPhoto;
