import * as faceapi from 'face-api.js';
import axios from 'axios';
import ExpressionsDetectionModel from '../models/ExpressionsDetectionModel';
import apiService from './apiService';

export default {
  async loadModels() {
    await Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
    ]);
    return true;
  },

  async saveExpressionDetection(detection = ExpressionsDetectionModel()) {
    try {
      let _request = {
        url: `${apiService.getUrl().faceRecognition}expressionDetection/createExpressionDetection`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          macAddress: detection.macAddress,
          eventId: detection.eventId,
          system: detection.system,
          scheduleId: detection.scheduleId,
          amount: detection.amount,
          neutral: detection.neutral,
          happy: detection.happy,
          sad: detection.sad,
          angry: detection.angry,
          fearful: detection.fearful,
          disgusted: detection.disgusted,
          surprised: detection.surprised,
        }
      }

      if (detection.id) {
        _request = {
          url: `${apiService.getUrl().faceRecognition}expressionDetection/updateExpressionDetection`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          data: {
            id: detection.id,
            macAddress: detection.macAddress,
            eventId: detection.eventId,
            system: detection.system,
            scheduleId: detection.scheduleId,
            amount: detection.amount,
            neutral: detection.neutral,
            happy: detection.happy,
            sad: detection.sad,
            angry: detection.angry,
            fearful: detection.fearful,
            disgusted: detection.disgusted,
            surprised: detection.surprised,
          }
        }
      }

      const _ret = await axios(_request);

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return ExpressionsDetectionModel(_ret.data.data);
    } catch (error) {
      console.log('Error saveExpressionDetection', error);
      throw error;
    }
  },

  async deleteExpressionDetectionsByEvent(eventId = '', system = '') {
    try {
      let _token = await apiService.getUserToken();

      let _request = {
        url: `${apiService.getUrl().faceRecognition}expressionDetection/deleteExpressionDetectionsByEvent`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `BEARER ${_token}`,
        },
        data: {
          eventId: eventId,
          system: system,
        }
      }
      const _ret = await axios(_request);

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;
    } catch (error) {
      console.log('Error saveExpressionDetection', error);
      throw error;
    }
  },

  async getExpressionDetections(
    eventId = '',
    system = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'created_at',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _ret = await axios({
        url: `${apiService.getUrl().faceRecognition}expressionDetection/getExpressionDetections?eventId=${eventId}&system=${system}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(ExpressionsDetectionModel({
          id: item.id,
          macAddress: item.macAddress,
          eventId: item.eventId,
          system: item.system,
          amount: item.amount,
          neutral: item.neutral,
          happy: item.happy,
          sad: item.sad,
          angry: item.angry,
          fearful: item.fearful,
          disgusted: item.disgusted,
          surprised: item.surprised,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;
    } catch (error) {
      console.log('Error getExpressionDetections', error);
      throw error;
    }
  }
}
