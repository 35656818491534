import React from 'react';
import Box from '@mui/material/Box';

const FormRow = ({
  children,
  id,
  width = '100%',
  minWidth = undefined,
  maxWidth = undefined,
  background = 'transparent',
  margin = 0,
  marginLeft = 0,
  marginRight = 0,
  padding = 0,
  paddingTop = padding && padding !== 0 ? padding : 0,
  paddingBottom = padding && padding !== 0 ? padding : 0,
  paddingLeft = padding && padding !== 0 ? padding : 0,
  paddingRight = padding && padding !== 0 ? padding : 0,
  height = undefined,
  minHeight = undefined,
  flexWrap = undefined,
  justifyContent,
  alignSelf,
  ...props
}) => {

  const styles = {
    container: {
      display: 'flex',
      backgroundColor: background,
      marginTop: margin,
      marginBottom: margin,
      marginLeft: marginLeft,
      marginRight: marginRight,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      height: height,
      minHeight: minHeight,
      justifyContent: justifyContent,
      alignSelf: alignSelf,
      flexDirection: 'row',
      flexWrap: flexWrap
    }
  };

  return (
    <Box
      key={id}
      style={styles.container}
    >
      {children}
    </Box>
  )
};

export default FormRow;
