import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  InputField,
  FormRow,
  Button,
  FormColumn,
  TextMedium,
  ButtonTransparent,
} from '../../components';

const RecoverPasswordForm = (props) => {
  const {
    dimensions,
    formControl,
    recoverPasswordForm,
    setRecoverPasswordForm,
    recoverPasswordFormError,
    sendEmailRecoverPassword,
    tryRecoverPassword,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  if (formControl === 'email') {
    return renderEmailForm();
  }
  return renderRecoverForm();

  function renderEmailForm() {
    return (
      <FormColumn alignItems='center' width={width} padding={10}>
        <FormRow paddingTop={20}>
          <FormRow justifyContent='center'>
            <TextMedium>{getDic("esqueceu-a-senha")}</TextMedium>
          </FormRow>
        </FormRow>
        <FormRow maxWidth={600}>
          <InputField
            value={recoverPasswordForm.email}
            title={`${getDic("login")}`}
            type='email'
            onChange={e => setRecoverPasswordForm({ ...recoverPasswordForm, email: e })}
            error={recoverPasswordFormError.email !== '' ? true : false}
            errorMessage={recoverPasswordFormError.email}
          />
        </FormRow>
        <FormRow maxWidth={600}>
          <FormRow paddingTop={20} justifyContent='center'>
            <Button
              background='green'
              width={width - 20}
              text={`${getDic("enviar")} ${getDic("email")}`}
              onPress={() => sendEmailRecoverPassword()}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    )
  }

  function renderRecoverForm() {
    return (
      <FormColumn alignItems='center' width={width} padding={10}>
        <FormRow paddingTop={20}>
          <FormRow justifyContent='center'>
            <TextMedium>{getDic("esqueceu-a-senha")}</TextMedium>
          </FormRow>
        </FormRow>
        <FormRow maxWidth={600}>
          <InputField
            value={recoverPasswordForm.email}
            title={`${getDic("login")}`}
            type='email'
            onChange={e => setRecoverPasswordForm({ ...recoverPasswordForm, email: e })}
            error={recoverPasswordFormError.email !== '' ? true : false}
            errorMessage={recoverPasswordFormError.email}
          />
        </FormRow>
        <FormRow maxWidth={600}>
          <InputField
            value={recoverPasswordForm.token}
            title={`Token`}
            onChange={e => setRecoverPasswordForm({ ...recoverPasswordForm, token: e })}
            secureTextEntry
            error={recoverPasswordFormError.token !== '' ? true : false}
            errorMessage={recoverPasswordFormError.token}
          />
        </FormRow>
        <FormRow maxWidth={600}>
          <InputField
            value={recoverPasswordForm.password}
            title={`${getDic("senha")}`}
            onChange={e => setRecoverPasswordForm({ ...recoverPasswordForm, password: e })}
            type='password'
            error={recoverPasswordFormError.password !== '' ? true : false}
            errorMessage={recoverPasswordFormError.password}
          />
        </FormRow>
        <FormRow maxWidth={600}>
          <InputField
            value={recoverPasswordForm.confirmPassword}
            title={`${getDic("confirmar")} ${getDic("senha")}`}
            onChange={e => setRecoverPasswordForm({ ...recoverPasswordForm, confirmPassword: e })}
            type='password'
            error={recoverPasswordFormError.confirmPassword !== '' ? true : false}
            errorMessage={recoverPasswordFormError.confirmPassword}
          />
        </FormRow>
        <FormRow maxWidth={600}>
          <FormRow paddingTop={20} justifyContent='center'>
            <Button
              width={width - 20}
              text={`${getDic("enviar")}`}
              onPress={() => tryRecoverPassword()}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    )
  }
};

export default RecoverPasswordForm;
