import React from 'react';
import Box from '@mui/material/Box';
import { motion } from "framer-motion";

export default ({
  children,
  id,
  text,
  onPress,
  width = null,
  maxWidth = undefined,
  background = "#456091",
  textColor = 'white',
  height = undefined,
  ...props
}) => {

  const styles = {
    component: {
      alignItems: "center",
      backgroundColor: background,
      padding: 8,
      width: width,
      maxWidth: maxWidth,
      borderRadius: 5,
      flex: 1,
      height: height,
      cursor: 'pointer',
    },
    text: {
      color: textColor,
      fontFamily: 'Verdana',
      fontSize: 16,
      textAlign: 'center',
    }
  };

  return (
    <motion.div
      whileHover={{ opacity: 0.7 }}
      whileTap={{ opacity: 0.2 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
      key={id}
      style={
        styles.component
      }
      onClick={onPress}
    >
      {children ? children : (<Box style={styles.text}>{text}</Box>)}
    </motion.div>
  )
};
