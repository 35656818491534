import React from 'react';
import { motion } from "framer-motion";

export default ({
  children,
  id,
  onPress,
  width = null,
  background = "transparent",
  padding = 0,
  ...props
}) => {

  const styles = {
    component: {
      alignItems: "center",
      backgroundColor: background,
      padding: padding,
      width: width,
      borderRadius: 5,
      cursor: 'pointer',
    }
  };

  return (
    <motion.div
      whileHover={{ opacity: 0.7 }}
      whileTap={{ opacity: 0.2 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
      key={id}
      style={
        styles.component
      }
      onClick={onPress}
    >
      {children}
    </motion.div>
  )
};
