import React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SideMenu from './SideMenu';
import Loading from './Loading';
import Alert from './Alert';
import ButtonTransparent from './ButtonTransparent';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const Container = ({
  children,
  width = null,
  background = '#f2f2f2',
  backgroundImage = null,
  padding = 0,
  height = null,
  minHeight = undefined,
  header,
  headerContent,
  headerSubContent,
  headerBackgroundColor = '#456091',
  headerTextColor = "white",
  headerPadding = 15,
  menu = false,
  backButton = false,
  navigation,
  scroll,
  scrollHorizontal = false,
  ...props
}) => {

  const [dimensions, setDimensions] = React.useState(getCurrentDimension());
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: '',
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [dimensions]);

  const navigateBack = React.useCallback(() => {
    setOpenSideMenu(false);
    window.history.back();
  }, []);

  const styles = {
    container: {
      backgroundColor: background,
      width: width || dimensions.width,
      height: height || dimensions.height - 1,
      minHeight: minHeight || dimensions.height - 1,
    },
    children: {
      padding: padding,
      flex: 1
    },
    headerContainer: {
      backgroundColor: headerBackgroundColor,
      padding: 5,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: '0px 0px 5px #000000',
      alignItems: 'center',
    },
    headerText: {
      color: headerTextColor,
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: 18,
      paddingLeft: menu ? 10 : undefined,
      alignSelf: 'center',
    },
    menuButton: {
      backgroundColor: headerBackgroundColor,
      width: 30,
      height: 30,
      borderRadius: 30,
    },
    menuButtonIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
    }
  };

  return (
    <Box
      key='container'
      style={
        styles.container
      }
    >
      {(header || headerContent) && (
        <Box key='header' style={styles.headerContainer}>
          {menu && (
            <ButtonTransparent onPress={() => setOpenSideMenu(!openSideMenu)} >
              <Box style={
                styles.menuButtonIcon
              }>
                <MenuIcon style={{ color: headerTextColor }} />
              </Box>
            </ButtonTransparent>
          )}
          {!menu && backButton && (
            <ButtonTransparent onPress={() => navigateBack()} >
              <Box style={
                styles.menuButtonIcon
              }>
                <ArrowBackIcon style={{ color: headerTextColor }} />
              </Box>
            </ButtonTransparent>
          )}
          {header ? (
            <Box style={styles.headerText}>{header}</Box>
          ) : headerContent ? (
            <>{headerContent}</>
          ) : null}
        </Box>
      )}
      {headerSubContent}

      <Box source={{ uri: backgroundImage }} resizemode='stretch' style={{ flex: 1 }}>
        <Box
          style={styles.children}
        >
          {children}
        </Box>
      </Box>

      {modalControl()}
    </Box>
  )

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (openSideMenu) {
      return (
        <SideMenu
          open={openSideMenu}
          onClose={() => setOpenSideMenu(false)}
        />
      )
    }
    return null;
  }
};

export default Container;
