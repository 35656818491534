import React, { useCallback, useRef, useState } from "react";
import * as faceapi from 'face-api.js';
import { getDic } from '../assets/i18n/dictionary';
import FormRow from "./FormRow";
import FormColumn from "./FormColumn";
import TextMedium from "./TextMedium";

const Camera = ({
  onFaceDetect,
  labeledFaceDescriptors,
  width,
  height,
  useBackCamera = false,
  ...props
}) => {
  const [faceNotFind, setFaceNotFind] = React.useState(false);

  const videoRef = React.useRef();
  const canvasRef = React.useRef();

  React.useEffect(() => {
    startVideo();
  }, [])

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 300,
          facingMode: useBackCamera ? 'environment' : 'user',
        }
      })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  }

  const handleVideoOnPlay = async () => {
    const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors);

    setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        setFaceNotFind(false);

        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
        const displaySize = {
          width: width || 640,
          height: height || 480
        }

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi
          .detectAllFaces(videoRef.current)
          .withFaceLandmarks()
          .withFaceDescriptors();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, width || 640, height || 480);

        const results = resizedDetections.map((d) => {
          return faceMatcher.findBestMatch(d.descriptor);
        });
        results.forEach((result, i) => {
          const box = resizedDetections[i].detection.box;

          if (
            result._distance < 0.45 &&
            result._label !== 'unknown' &&
            onFaceDetect
          ) {
            onFaceDetect(result);
          }

          if (result._label === 'unknown') {
            setFaceNotFind(true);
          }

          const drawBox = new faceapi.draw.DrawBox(box, {
            label: result,
            boxColor: 'transparent',
            drawLabelOptions: {
              fontColor: 'transparent'
            }
          });
          drawBox.draw(canvasRef.current);
        });
      }
    }, 2500)
  }

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    // setCaptureVideo(false);
  }

  return (
    <FormColumn>
      <FormRow>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            ref={videoRef}
            height={height || 480}
            width={width || 640}
            onPlay={handleVideoOnPlay}
          // style={{
          //   height: `${height || 480}px`,
          //   width: `${width || 640}px`,
          //   // objectFit: 'cover'
          // }}
          />
          <canvas ref={canvasRef} style={{ position: 'absolute' }} />
        </div>
      </FormRow>
      <FormRow justifyContent='center'>
        <TextMedium fontWeight='bold' color={faceNotFind ? "red" : 'transparent'}>
          {getDic("participante-nao-encontrado")}
        </TextMedium>
      </FormRow>
    </FormColumn>
  );
};

export default Camera;
