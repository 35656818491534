import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  InputField,
  FormRow,
  Button,
  FormColumn,
  ButtonTransparent,
  TextSmall,
} from '../../components';

const LoginForm = (props) => {
  const {
    dimensions,
    loginForm,
    setLoginForm,
    loginFormError,
    tryLogin,
    openPageRecoverPassword,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormColumn alignItems='center' width={width} padding={10}>
      <FormRow>
        <InputField
          value={loginForm.login}
          title={`${getDic("login")}`}
          type='email-address'
          onChange={e => setLoginForm({ ...loginForm, login: e })}
          error={loginFormError.login !== '' ? true : false}
          errorMessage={loginFormError.login}
        />
      </FormRow>
      <FormRow>
        <InputField
          value={loginForm.password}
          title={`${getDic("senha")}`}
          onChange={e => setLoginForm({ ...loginForm, password: e })}
          type='password'
          error={loginFormError.password !== '' ? true : false}
          errorMessage={loginFormError.password}
        />
      </FormRow>
      <FormRow>
        <FormRow paddingTop={20} justifyContent='center'>
          <Button
            width={width - 20}
            text={`${getDic("entrar")}`}
            onPress={() => tryLogin()}
          />
        </FormRow>
      </FormRow>
      <FormRow paddingTop={20}>
        <FormRow justifyContent='center'>
          <ButtonTransparent onPress={() => openPageRecoverPassword()}>
            <TextSmall>{getDic("esqueceu-a-senha")}</TextSmall>
          </ButtonTransparent>
        </FormRow>
      </FormRow>
    </FormColumn>
  )
};

export default LoginForm;
