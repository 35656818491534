const obj = {
  id: '',
  eventId: '',
  system: '',
  guestId: '',
  detection: '',
}

export default function FaceDetectionModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
